export function random(min = 0, max = 1, round = false) {
    const rnd = Math.random() * (max - min) + min;
    if(round) return Math.round(rnd);
    return rnd;
}

export function uuid() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}