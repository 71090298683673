import { random } from "./utils";

const COLORS = [
    [217, 65, 50],
    [212, 73, 60],
];

class Particle {
    constructor({x, y, radius, color: [hue, saturation, luminosity]}) {
        this.x = x;
        this.y = y;
        this.radius = radius;
        this.hslString = `${hue}deg, ${saturation}%, ${luminosity + random(-20, 20, true)}%`;

        this.vy = random(-1, 1) * 2;
        this.vx = random(-1, 1) * 2;

        this.sinValue = random(-1, 1);
    }

    animate = (ctx, width, height) => {
        this.sinValue += 0.01;

        this.radius += Math.sin(this.sinValue);

        this.x += this.vx;
        this.y += this.vy;

        if(this.x < -100) {
            this.vx *= -1;
            this.x += 10;
        } else if(this.x > width + 100) {
            this.vx *= -1;
            this.x -= 10;
        }

        if(this.y < -100) {
            this.vy *= -1;
            this.y += 10;
        } else if(this.y > height + 100) {
            this.vy *= -1;
            this.y -= 10;
        }

        ctx.beginPath();

        const gradient = ctx.createRadialGradient(
            this.x,
            this.y,
            Math.abs(this.radius * 0.5),
            this.x,
            this.y,
            Math.abs(this.radius)
        );

        gradient.addColorStop(0, `hsla(${this.hslString}, 1)`);
        gradient.addColorStop(1, `hsla(${this.hslString}, 0)`);
        ctx.fillStyle = gradient;

        // ctx.fillStyle = `hsla(${this.hslString}, 1)`;

        ctx.arc(this.x, this.y, Math.abs(this.radius), 0, Math.PI * 2, false);
        ctx.fill();
    }
}

export default class Background {
    constructor() {
        this.canvas = document.createElement('canvas');
        this.canvas.classList.add('background');

        document.body.appendChild(this.canvas);

        this.ctx = this.canvas.getContext('2d');

        this.totalParticles = 20;
        this.particles = [];
        this.maxRadius = 0;
        this.minRadius = 0;

        this.width = 0;
        this.height = 0;

        this.resize();
        this.createParticles();

        window.addEventListener('resize', this.resize, false);
        window.addEventListener('visibilitychange', this.handleVisibilityChange, false);

        window.requestAnimationFrame(this.animate);
    }

    resize = () => {
        this.height = document.body.clientHeight;
        this.width = document.body.clientWidth;

        const baseRadius = Math.min(this.width, this.height);
        this.maxRadius = baseRadius / 2;
        this.minRadius = baseRadius / 3;

        this.canvas.setAttribute('width', this.width);
        this.canvas.setAttribute('height', this.height);

        this.ctx.globalCompositeOperation = "luminosity";
    }

    createParticles = () => {
        this.particles = Array
            .from({length: this.totalParticles}, (_,i) => i + 1 * 5)
            .map((i) => new Particle({
                x: random() * this.width,
                y: random() * this.height,
                radius: random(this.minRadius, this.maxRadius / i),
                color: COLORS[i % COLORS.length],
            }));
    }

    animate = () => {
        this.raf = window.requestAnimationFrame(this.animate);

        this.ctx.clearRect(0,0, this.width, this.height);
        this.ctx.fillStyle = `hsl(${COLORS[0][0]}deg, ${COLORS[0][1]}%, ${COLORS[0][2]}%)`
        this.ctx.fillRect(0,0, this.width, this.height);

        this.particles
            .forEach((particle) => particle.animate(this.ctx, this.width, this.height));
    }

    handleVisibilityChange = () => {
        if(document.hidden) {
            window.cancelAnimationFrame(this.raf);
        } else {
            this.animate();
        }
    }
}