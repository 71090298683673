import { random, uuid } from "./utils";

const EMOJIS = [
    '🤖',
    '👨',
    '👨‍💻',
    '💎',
    '🙌',
    '🎓',
    '🎮',
    '🏹',
    '📷',
    '🍔',
    '🍕',
    '🥐',
    '🍹',
    '🍸',
    '🍷',
    '⛺',
    '👾'
]

class Emoji {
    constructor({x, y, rotation, emoji}) {
        this.x = x;
        this.y = y;
        this.rotation = rotation;
        this.emoji = emoji;

        this.particle = document.createElement('div');

        this.particle.textContent = this.emoji;

        this.vy = random(-1, 1) * 2;
        this.vx = random(-1, 1) * 2;
    }

    animate = (width, height) => {
        this.x += this.vx;
        this.y += this.vy;
        this.rotation += 0.5;

        if(this.x < -100) {
            this.vx *= -1;
            this.x += 10;
        } else if(this.x > width + 100) {
            this.vx *= -1;
            this.x -= 10;
        }

        if(this.y < -100) {
            this.vy *= -1;
            this.y += 10;
        } else if(this.y > height + 100) {
            this.vy *= -1;
            this.y -= 10;
        }

        this.particle.style.setProperty('transform', `translate(${this.x}px, ${this.y}px) rotate(${this.rotation}deg)`);
    }

    appendTo(parent) {
        parent.appendChild(this.particle);
    }
}

export default class ForeGround {
    constructor() {
        this.wrapper = document.createElement('div');
        this.wrapper.classList.add('foreground');

        document.body.appendChild(this.wrapper);

        this.totalParticles = EMOJIS.length;
        this.particles = [];
        this.maxRadius = 0;
        this.minRadius = 0;
        this.playing = window.matchMedia('prefers-reduced-motion').matches;

        this.width = 0;
        this.height = 0;

        window.addEventListener('resize', this.resize, false);
        this.resize();
        this.createParticles();

        window.requestAnimationFrame(this.animate);

        window.addEventListener('visibilitychange', this.handleVisibilityChange, false);
    }

    resize = () => {
        this.height = document.body.clientHeight;
        this.width = document.body.clientWidth;

        const baseRadius = Math.min(this.width, this.height);
        this.maxRadius = baseRadius / 5;
        this.minRadius = baseRadius / 10;

        this.wrapper.setAttribute('width', this.width);
        this.wrapper.setAttribute('height', this.height);
    }

    createParticles = () => {
        this.particles = EMOJIS
            .map((emoji) => new Emoji({
                x: random() * this.width,
                y: random() * this.height,
                rotation: random(0, 360, true),
                emoji,
            }));

        this.particles.forEach((particle) => particle.appendTo(this.wrapper));
    }

    animate = () => {
        this.particles
            .forEach((particle, i) => particle.animate(this.width, this.height, i));

        this.raf = window.requestAnimationFrame(this.animate);
    }

    handleVisibilityChange = () => {
        if(document.hidden) {
            window.cancelAnimationFrame(this.raf);
        } else {
            this.animate();
        }
    }
}